.contact {
  min-height: 100vh;
  max-width: var(--max-width);
  margin: auto;
  display: flex;
  align-items: center;
  gap: var(--gap-broad);

  @include mobile {
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }

  .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--gap-broad);

    @include mobile {
      gap: 20px;
      text-align: center;
      align-items: center;
      margin-top: 70px;
    }

    h1 {
      font-size: 100px;
      line-height: 88px;

      @include mobile {
        font-size: 36px;
      }
    }

    span {
      font-weight: 300;
    }
  }

  .formContainer {
    flex: 1;
    position: relative;

    @include mobile {
      width: 100%;
      padding: 50px;
    }

    .phoneSvg {
      stroke: var(--brand);
      position: absolute;
      margin: auto;
      z-index: -1;

      @include mobile {
        width: 90vw;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        svg {
          width: 100%;
          height: auto;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      input,
      textarea {
        padding: 20px;
        background-color: transparent;
        border: 1px solid var(--card-border);
        color: var(--text-color);
        border-radius: var(--border-radius);

        @include mobile {
          padding: 10px;
        }
      }
    }

    button {
      background-color: var(--brand);
      padding: 20px;
      border: none;
      cursor: pointer;
      font-weight: 500;
      border-radius: var(--border-radius);

      @include mobile {
        padding: 10px;
      }
    }
  }
}
