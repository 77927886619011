.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-color);

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 400px;
    background-color: var(--menu-bg-color);
    z-index: 1;

    @include mobile {
      width: 100%;
    }

    .links {
      //position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--gap);

      a {
        font-size: 40px;

        @include mobile {
          font-size: 20px;
        }
      }
    }
  }

  button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    top: 25px;
    left: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
