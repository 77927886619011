.hero {
  min-height: calc(100vh - 100px);
  overflow: hidden;
  //background: var(--background-gradient);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wrapper {
    position: relative;
    width: 100%;
    max-width: var(--max-width);
    z-index: 1;
    flex-grow: 1;

    .textContainer {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--gap-broad);
      margin: 1rem 0;

      @include mobile {
        height: 50%;
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      h2 {
        font-size: var(--font-size-h-small);
        color: var(--brand);
      }

      h1 {
        font-size: var(--font-size-h-big);
        line-height: var(--font-size-h-big);
        @include mobile {
          text-align: center;
        }
      }

      .buttons {
        button {
          padding: 20px;
          border: 1px solid var(--text-color);
          border-radius: var(--border-radius);
          background-color: transparent;
          color: var(--text-color);
          margin-right: 20px;
          cursor: pointer;
          font-weight: 300;
        }
      }

      img {
        width: 50px;
      }
    }
  }

  .slidingTextContainer {
    position: absolute;
    font-size: 50vh;
    line-height: 50vh;
    bottom: 0;
    white-space: nowrap;
    color: rgba(var(--brand-rgb), 0.3);
    width: 50%;
    font-weight: bold;
  }

  .imageContainer {
    position: absolute;
    bottom: 0;
    right: 0;

    @include mobile {
      height: 50%;
      width: 100%;
      top: unset;
      bottom: 0;
    }

    img {
      width: 250px;
      height: 250px;
      object-fit: cover;
    }
  }
}
