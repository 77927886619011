/* _global.scss */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  background-color: var(--background-color); /* Default: light mode */
  color: var(--text-color);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  height: 100%;
}

#root {
  height: 100%;
}
.content {
  display: grid;
  grid-template-columns: auto 1fr;
  .main-content {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
    .content-container {
      max-width: var(--max-width);
      width: 100%;
      margin: 0 auto;
      padding: 1rem;
    }
  }
}
a {
  text-decoration: none;
  color: inherit;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1.5rem;
}

.gx-1 {
  > * {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }
}
.gx-2 {
  > * {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
.gx-3 {
  > * {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
.gx-4 {
  > * {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
}
.gy-1 {
  > * {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }
}
.gy-2 {
  > * {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
.gy-3 {
  > * {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
.gy-4 {
  > * {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
}
/*.gx-1,
.gx-2,
.gx-3,
.gx-4,
.gy-1,
.gy-2,
.gy-3,
.gy-4 {
  > * {
    padding: calc(var(--gutter-y) * 0.5) calc(var(--gutter-x) * 0.5) !important;
  }
}*/

.page-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.no-border {
  border: none !important;
}

@include mobile {
  .content {
    display: block;
    min-height: 100vh;
    > div {
      min-height: 100vh;
      .main-content {
        min-height: calc(100vh - 60px);
      }
    }
  }
}
