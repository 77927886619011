/* _responsive.scss */
@include tablet {
  :root {
    --max-width: 868px !important; /* Tablet */
  }
}

@include desktop {
  :root {
    --max-width: 1024px !important; /* Desktop */
    --gap-broad: 30px;
    --font-size-h-big: 88px;
    --font-size-h-small: 30px;
  }
}

@include large-desktop {
  :root {
    --max-width: 1366px !important; /* Large screens */
    --gap-brad: 40px;
    --font-size-h-big: 88px;
    --font-size-h-small: 30px;
  }
}
