.navbar {
  height: 100px;

  .wrapper {
    max-width: var(--max-width);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @include mobile {
      justify-content: flex-end;
      padding: 20px;
    }

    span {
      font-weight: bold;

      @include mobile {
        display: none;
      }
    }

    .header-menu {
      display: flex;
      flex-direction: row;
      gap: var(--gap);
    }
  }
}
