.services {
  //background: var(--background-gradient);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .textContainer {
    flex: 1;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: var(--gap);

    @include mobile {
      align-self: center;
      text-align: center;
      flex-direction: column;
      padding-top: 1rem;
    }

    p {
      font-weight: 200;
      font-size: 20px;
      color: var(--text-color);
      text-align: right;
    }
    hr {
      width: 500px;
      border: none;
      border-top: 0.5px solid var(--brand);

      @include mobile {
        width: 100%;
      }
    }
  }

  .titleContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      width: 100%;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 50px;

      @include mobile {
        flex-direction: column;
        text-align: center;
        gap: 20px;
      }

      img {
        width: 300px;
        height: 100px;
        border-radius: 50px;
        object-fit: cover;

        @include mobile {
          width: 200px;
          height: 50px;
        }
      }

      h1 {
        font-size: 96px;
        font-weight: 100;

        @include mobile {
          font-size: 36px;
        }
      }

      button {
        width: 300px;
        height: 100px;
        border-radius: 50px;
        background-color: var(--brand);
        border: none;
        font-size: 24px;
        cursor: pointer;

        @include mobile {
          font-size: 16px;
          width: 150px;
          height: 50px;
        }
      }
    }
  }

  .listContainer {
    flex: 2;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 350px));
    justify-content: center;
    gap: var(--gap);
    max-width: var(--max-width);
    margin: 0 auto 2rem;

    @include mobile {
      flex-direction: column;
      width: 100%;
    }

    .box {
      padding: 50px;
      border: 0.5px solid var(--card-border);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: var(--card-background);
      box-shadow: var(--card-box-shadow);
      &:hover {
        box-shadow:
          0 8px 10px rgba(0, 0, 0, 0.15),
          0 4px 6px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px); /* Slight lift effect */
        transition: all 0.3s ease;
      }

      @include mobile {
        border: none;
        align-items: center;
        gap: 20px;
        padding: 20px;
      }

      p {
        @include mobile {
          display: none;
        }
      }

      ul {
        list-style-position: inside;
        li:marker {
          color: var(--brand);
        }
      }
    }
  }
}
