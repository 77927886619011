#sidebar {
  --line-clr: #42434a;
  --hover-crl: #222533;
  --text-clr: #e6e6ef;
  --accent-clr: #5e63ff;
  --secondary-text-clr: #b0b3c1;

  box-sizing: border-box;
  height: 100vh;
  width: 250px;
  padding: 5px 1em;
  background-color: var(--menu-bg-color);
  border-right: 1px solid var(--line-clr);
  position: sticky;
  top: 0;
  align-self: start;
  transition: 300ms ease-in-out;
  z-index: 9999;
  &.close {
    padding: 5px;
    width: 60px;
    overflow: hidden;
    text-wrap: nowrap;

    #toggle-btn {
      rotate: 180deg;
    }
    a span {
      display: none;
    }
    li:last-child {
      display: none;
    }
    .dropdown-btn {
      span {
        display: none;
      }
      .submenu-chevron {
        display: none;
      }
    }
  }

  svg {
    flex-shrink: 0;
    fill: var(--text-clr);
  }

  > ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    > li:first-child {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;

      .logo {
        font-weight: 600;

        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  a:hover,
  .dropdown-btn:hover {
    background-color: var(--hover-crl);
  }

  a span,
  .dropdown-btn span {
    flex-grow: 1;
  }

  ul {
    list-style: none;

    li.active a {
      color: var(--accent-clr);

      svg {
        fill: var(--accent-clr);
      }
    }
    li:last-child {
      margin-top: auto;
      ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border-top: 1px solid var(--line-clr);
        li {
          border-radius: 0.5em;
          padding: 0.85em;
          text-decoration: none;
          color: var(--text-clr);
          cursor: pointer;
          &:hover {
            background-color: var(--hover-crl);
          }
          svg {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }

  .dropdown-btn {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    &.show {
      svg:last-child {
        rotate: 180deg;
      }
      + .sub-menu {
        grid-template-rows: 1fr;
      }
    }
    svg:last-child {
      transition: 300ms ease-in-out;
    }

    + .sub-menu {
      display: grid;
      grid-template-rows: 0fr;
      transition: 300ms ease-in-out;

      > div {
        overflow: hidden;
      }
    }
  }
  a,
  .dropdown-btn,
  .logo {
    border-radius: 0.5em;
    padding: 0.85em;
    text-decoration: none;
    color: var(--text-clr);
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .sub-menu a {
    padding-left: 2em;
  }

  #toggle-btn {
    margin-left: 1em;
    padding: 1em;
    border: none;
    border-radius: 0.5em;
    background: none;
    cursor: pointer;
    transition: 300ms ease-in-out;

    &:hover {
      background-color: var(--hover-crl);
    }

    svg {
      transition: rotate 150ms ease;
    }
  }
}

@include mobile {
  #sidebar {
    height: 60px;
    width: 100vw;
    border-right: none;
    border-top: 1px solid var(--line-clr);
    padding: 0;
    position: fixed;
    top: unset;
    bottom: 0;

    ul {
      padding: 0;
      display: grid;
      grid-auto-columns: 60px;
      grid-auto-flow: column;
      align-items: center;
      overflow-x: scroll;
      overflow-y: hidden;
      li:last-child {
        ul {
          overflow: unset;
          border: none;
          display: grid;
          justify-content: flex-start;
          li {
            display: flex;
            width: 60px;
            height: 60px;
            border-radius: 0;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    ul li {
      height: 100%;
    }

    ul a,
    ul .dropdown-btn {
      width: 60px;
      height: 60px;
      padding: 0;
      border-radius: 0;
      justify-content: center;
    }
    ul li span,
    > ul > li:first-child,
    .dropdown-btn svg:last-child {
      display: none;
    }

    .dropdown-btn.show + .sub-menu {
      position: fixed;
      bottom: 60px;
      left: 0;
      box-sizing: border-box;
      height: 60px;
      width: 100%;
      background-color: var(--hover-crl);
      border-top: 1px solid var(--line-clr);
      display: flex;
      justify-content: center;
      > div {
        overflow-x: auto;
      }
      li {
        display: inline-flex;
      }
      a {
        box-sizing: border-box;
        padding: 1em;
        width: auto;
        justify-content: center;
      }
    }
  }
}
