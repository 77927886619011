/* _sections.scss */
section {
  min-height: 100vh;
  scroll-snap-align: center;
}

hr {
  border-top: 1px solid var(--cui-primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--cui-primary);
}

button {
  &:disabled {
    cursor: not-allowed;
    pointer-events: unset !important;
  }
  &.btn-danger,
  &.btn-danger:hover,
  &.btn-danger:disabled,
  &.btn-success,
  &.btn-success:hover,
  &.btn-success:disabled {
    color: #ffff;
  }
}

input,
select,
textarea {
  &.success {
    border-color: $success;
    &:focus {
      border-color: color-mix(in srgb, var(--cui-success), #000 15%);
      box-shadow: 0 0 0 0.25rem rgba(46, 184, 92, 0.25);
    }
  }
  &.danger {
    border-color: $danger;
    &:focus {
      border-color: color-mix(in srgb, var(--cui-body-bg), #000 15%);
      box-shadow: 0 0 0 0.25rem rgba(229, 83, 83, 0.25);
    }
  }
}

.text-primary {
  color: var(--cui-primary);
}

.text-success {
  color: var(--cui-success);
}

.text-danger {
  color: var(--cui-danger);
}

.flex-right {
  display: flex;
  justify-content: end;
}

.input-group {
  .input-group-text {
    &.success {
      color: var(--cui-success);
      border: 1px solid var(--cui-success);
    }
    &.danger {
      color: var(--cui-danger);
      border: 1px solid var(--cui-danger);
    }
  }
  .form-multi-select-input-group {
    border-radius: 0;
  }

  .input-addon {
    flex: 1 1 auto;
    position: relative;
    &:not(:last-child) input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:not(:first-child) input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .addon {
      position: absolute;
      top: 0.375rem;
      right: 0.75rem;
      font-size: 1.5rem;
      line-height: 1.5;
    }
    .input-delete {
      cursor: pointer;
    }
  }
}

.password-validation {
  border: 1px solid var(--cui-primary);
  border-radius: var(--cui-border-radius);
  display: inline-block;
  padding: 0.5rem;
  margin-left: 46px;
  &.primary {
    border-color: var(--cui-primary);
  }
  &.success {
    border-color: var(--cui-success);
  }
  &.danger {
    border-color: var(--cui-danger);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.flag-placement {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1;
  .flag-toggle {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.card-floating-btn-left {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  cursor: pointer;
}

.delete-photo-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}

.flex-h-space-between {
  justify-content: space-between;
}

.flex-v-space-between {
  align-items: space-between;
}

.toast .accordion-button {
  padding: 0.5rem 1rem;
}
