//
// Base styles
//

.accordion {
  // scss-docs-start accordion-css-vars
  --accordion-color: #ffffffde;
  --accordion-bg: #212631;
  --accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --accordion-border-color: #323a49;
  --accordion-border-width: 1px;
  --accordion-border-radius: calc(var(--cui-border-radius) - var(--cui-border-width));
  --accordion-inner-border-radius: #{$accordion-inner-border-radius};
  --accordion-btn-padding-x: 1.25rem;
  --accordion-btn-padding-y: 1rem;
  --accordion-btn-color: #ffffffde;
  --accordion-btn-bg: #212631;
  --accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='rgba(37, 43, 54, 0.95)' stroke-linecap='round' stroke-linejoin='round' viewBox='0 0 16 16'%3E%3Cpath d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  --accordion-btn-icon-width: 1.25rem;
  --accordion-btn-icon-transform: rotate(-180deg);
  --accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%233634a3' stroke-linecap='round' stroke-linejoin='round' viewBox='0 0 16 16'%3E%3Cpath d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  --accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(88, 86, 214, 0.25);
  --accordion-body-padding-x: 1.25rem;
  --accordion-body-padding-y: 1rem;
  --accordion-active-color: #403f98;
  --accordion-active-bg: #cfc7f3;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--accordion-btn-padding-y) var(--accordion-btn-padding-x);
  color: var(--accordion-btn-color);
  background-color: var(--accordion-btn-bg);
  border: 0;
  overflow-anchor: none;
  transition: var(--accordion-transition);

  &:not(.collapsed) {
    color: var(--accordion-active-color);
    background-color: var(--accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--accordion-border-width)) 0 var(--accordion-border-color);

    &::after {
      background-image: var(--accordion-btn-active-icon);
      transform: var(--accordion-btn-icon-transform);
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: var(--accordion-btn-icon-width);
    height: var(--accordion-btn-icon-width);
    @include ltr-rtl('margin-left', auto);
    content: '';
    background-image: var(--accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--accordion-btn-icon-width);
    @include transition(var(--accordion-btn-icon-transition));
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    outline: 0;
    box-shadow: var(--accordion-btn-focus-box-shadow);
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--accordion-color);
  background-color: var(--accordion-bg);
  border: var(--accordion-border-width) solid var(--accordion-border-color);

  &:first-of-type {
    @include border-top-radius(var(--accordion-border-radius));

    > .accordion-header .accordion-button {
      @include border-top-radius(var(--accordion-inner-border-radius));
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  // Only set a border-radius on the last item if the accordion is collapsed
  &:last-of-type {
    @include border-bottom-radius(var(--accordion-border-radius));

    > .accordion-header .accordion-button {
      &.collapsed {
        @include border-bottom-radius(var(--accordion-inner-border-radius));
      }
    }

    > .accordion-collapse {
      @include border-bottom-radius(var(--accordion-border-radius));
    }
  }
}

.accordion-body {
  padding: var(--accordion-body-padding-y) var(--accordion-body-padding-x);
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  > .accordion-item {
    border-right: 0;
    border-left: 0;
    @include border-radius(0);

    &:first-child {
      border-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }

    > .accordion-header .accordion-button {
      &,
      &.collapsed {
        @include border-radius(0);
      }
    }

    > .accordion-collapse {
      @include border-radius(0);
    }
  }
}
