.card-group {
  display: flex;
  flex-flow: row wrap;
  @include mobile {
    display: block;
  }
  & > .card {
    flex: 1 0;
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @include mobile {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      @include mobile {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }
    }
  }
}

.card {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: var(--card-background-color);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius);
  color: var(--card-text-color);
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  box-shadow: var(--card-box-shadow);

  &.height-100 {
    height: 100% !important;
  }

  &.hover:hover {
    box-shadow: var(--card-box-shadow-hover);
    transform: translateY(-2px);
    transition: all 0.3s ease;
  }

  .card-floating-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    z-index: 2;
  }
  .card-img-top {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    width: 100%;
    object-fit: cover;
  }

  .card-img-bottom {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    width: 100%;
    object-fit: cover;
  }

  .card-img-overlay {
    border-radius: var(--border-radius);
    bottom: 0;
    left: 0;
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
  }
  > :first-child {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  > :last-child {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  .card-header {
    background-color: var(--card-background-secondary-color);
    border-bottom: 1px solid var(--card-border-color);
    color: var(--card-text-color);
    margin-bottom: 0;
    padding: 1rem;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1rem;
    .card-title {
      margin-bottom: 0.5rem;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.2;
      margin-top: 0;
    }
    .card-subtitle {
      font-size: 1.1rem;
      font-weight: 500;
    }
    .card-text {
      p {
        margin-bottom: 1rem;
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .card-footer {
    background-color: var(--card-background-secondary-color);
    border-top: 1px solid var(--card-border-color);
    padding: 1rem;
    &:last-child {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
  }
}
