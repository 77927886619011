.drag-drop {
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.document-uploader {
  border: 2px dashed var(--cui-primary);
  background-color: var(--cui-card-bg);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  label {
    display: flex;
    width: 100%;
    justify-content: center;
    cursor: pointer;
  }

  &.active {
    border-color: #6dc24b;
  }

  .upload-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    svg {
      margin-right: 1rem;
    }

    div {
      p {
        margin: 0;
        font-size: 16px;
      }

      p:first-child {
        font-weight: bold;
      }
    }
  }

  .success-file {
    display: flex;
    align-items: center;
    color: #6dc24b;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
  }

  input[type='file'] {
    display: none;
  }
}

.file-list {
  img {
    width: 100%;
  }
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;

  .file-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;

    p {
      margin: 0;
      font-size: 14px;
      color: #333;
    }
  }

  .file-actions {
    cursor: pointer;
    svg {
      font-size: 18px;
      color: var(--cui-danger);
    }
    &:hover {
      svg {
        color: var(--cui-btn-hover-bg);
      }
    }
  }
}

.dragging {
  //width: 60vmin;
  //height: 50vmin;
  display: grid;
  place-content: center;
  //color: white;
  //text-shadow: 0 1px 0 #000;

  --border-angle: 0turn; // For animation.
  --main-bg: conic-gradient(
    from var(--border-angle),
    var(--cui-primary),
    var(--cui-tertiary-bg) 5%,
    var(--cui-tertiary-bg) 60%,
    var(--cui-primary) 95%
  );

  border: solid 5px transparent;
  border-radius: 2em;
  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    #08f,
    #f03 99%,
    transparent
  );

  background: 
    // padding-box clip this background in to the overall element except the border.
    var(--main-bg) padding-box,
    // border-box extends this background to the border space
    var(--gradient-border) border-box,
    // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: paused;
  }
}

.range-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.edit-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@property --border-angle {
  syntax: '<angle>';
  inherits: true;
  initial-value: 0turn;
}
