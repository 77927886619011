.dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    min-width: 150px;
    background-color: var(--menu-bg-color);
    z-index: 1;

    &.bg-theme {
      height: 170px;
    }

    &.bg-lang {
      height: 130px;
    }

    &.bg-user {
      height: 130px;
    }

    @include mobile {
      //width: 100%;
    }

    .links {
      margin-top: 50px;
      top: 0;
      width: 100%;
      height: 100%;
      list-style: none;
      li {
        padding: 0.5rem 1rem 0.5rem 1rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        &:hover {
          background-color: var(--menu-bg-hover-color);
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: var(--gap-small);
        }
      }
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
    color: var(--text-color);
    span {
      position: absolute;
      left: 50px;
      text-wrap: nowrap;
    }
  }
}
