.row {
  display: flex;
  flex-wrap: wrap;

  > * {
    max-width: 100%;
    width: 100%;
  }
}

// Mixin to generate row-cols for different breakpoints
@mixin generate-row-cols($num, $suffix: '') {
  // Generate class with the custom suffix
  .row-cols#{$suffix}-#{$num} {
    display: flex;
    flex-wrap: wrap;
    > * {
      flex: 0 0 auto;
      width: calc(100% / #{$num});
    }
  }
}

// Generate row-cols classes
@for $i from 1 through 8 {
  @include generate-row-cols($i);
}

@include mobile {
  @for $i from 1 through 8 {
    @include generate-row-cols($i, '-mob');
  }
}

@include tablet {
  @for $i from 1 through 8 {
    @include generate-row-cols($i, '-tab');
  }
}

@include desktop {
  @for $i from 1 through 8 {
    @include generate-row-cols($i, '-desk');
  }
}

@include large-desktop {
  @for $i from 1 through 8 {
    @include generate-row-cols($i, '-desk-l');
  }
}
