/* _mixins.scss */
@mixin mobile {
  @media (max-width: 738px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 739px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1366px) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: 1367px) {
    @content;
  }
}
