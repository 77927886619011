/* _variables.scss */
:root {
  /* Page setup */
  --background-color: #ffffff;
  --text-color: #212529;

  --menu-bg-color: #007bff;
  --menu-bg-hover-color: #e9ecef;

  --card-background: #ffffff;
  --card-border: #e0e0e0;
  --card-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

  --background-gradient: linear-gradient(135deg, #f6d365 0%, #fda085 100%);

  --gap: 20px;
  --gap-broad: 20px;
  --gap-small: 0.8rem;
  --max-width: 100%;
  --border-radius: 0.375rem;
  --font-size-h-big: 30px;
  --font-size-h-small: 30px;
  --gutter-x: 0;
  --gutter-y: 0;

  /* Colors */
  --brand: #990000;
  --brand-rgb: 153, 0, 0;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --success: #198754;
  --info: #0dcaf0;
  --warning: #ffc107;
  --danger: #dc3545;
  --success-text-emphasis: #0a3622;
  --info-text-emphasis: #055160;
  --warning-text-emphasis: #664d03;
  --danger-text-emphasis: #58151c;
  --success-bg-subtle: #d1e7dd;
  --info-bg-subtle: #cff4fc;
  --warning-bg-subtle: #fff3cd;
  --danger-bg-subtle: #f8d7da;
  --success-border-subtle: #a3cfbb;
  --info-border-subtle: #9eeaf9;
  --warning-border-subtle: #ffe69c;
  --danger-border-subtle: #f1aeb5;
  --link-color: #0d6efd;
  --link-hover-color: #0a58ca;
  --border-color: #dee2e6;
  --form-valid-color: #198754;
  --form-valid-border-color: #198754;
  --form-invalid-color: #dc3545;
  --form-invalid-border-color: #dc3545;

  /* Font */
  --font-size: 1rem;
  --font-weight: 400;
  --line-height: 1.5;

  /* Miscellanies */
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --focus-ring-width: 0.25rem;
  --focus-ring-opacity: 0.25;
  --focus-ring-color: rgba(13, 110, 253, 0.25);
}

[data-theme='dark'] {
  --background-color: #212529;
  --text-color: #f8f9fa;

  --menu-bg-color: #11121a;
  --menu-bg-hover-color: #495057;

  --card-background-color: #11121a;
  --card-background-secondary-color: #1a1b25;
  --card-text-color: #f8f9fa;
  --card-border-color: #5a5d72;
  --card-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.15);
  --card-box-shadow-hover: 0 8px 10px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.1);

  --background-gradient: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);

  --success-text-emphasis: #75b798;
  --info-text-emphasis: #6edff6;
  --warning-text-emphasis: #ffda6a;
  --danger-text-emphasis: #ea868f;
  --primary-bg-subtle: #031633;
  --success-bg-subtle: #051b11;
  --info-bg-subtle: #032830;
  --warning-bg-subtle: #332701;
  --danger-bg-subtle: #2c0b0e;
  --success-border-subtle: #0f5132;
  --info-border-subtle: #087990;
  --warning-border-subtle: #997404;
  --danger-border-subtle: #842029;
  --link-color: #6ea8fe;
  --link-hover-color: #8bb9fe;
  --border-color: #495057;
  --form-valid-color: #75b798;
  --form-valid-border-color: #75b798;
  --form-invalid-color: #ea868f;
  --form-invalid-border-color: #ea868f;
}
